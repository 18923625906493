<template>
  <v-text-field class="date-picker" type="date" :value="date" @input="updated" v-bind="$attrs" v-if="simple">
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-text-field>

  <v-menu v-else v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px"
    min-width="auto">
    <template v-slot:activator="{ on }">
      <v-text-field :value="formatted" v-bind="$attrs" append-icon="mdi-calendar" readonly v-on="on"></v-text-field>
    </template>
    <v-date-picker v-model="date" @input="menu = false" event-color="green darken-1" color="grey darken-3"
      :allowed-dates="allowedDates" :events="availableChargeDates" no-title scrollable></v-date-picker>
  </v-menu>
</template>

<script lang="js">
import uniqid from 'uniqid';
import api from "@/services/api-service";
import moment from "moment";
export default {
  name: "InputFieldDate",
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: "DD/MM/YYYY",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    blockBankHolidayes: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value(v, ov) {
      this._setDate(v);
      this._setUniqKey();
    },
    date(v) {
      this.updated(v);
    }
  },

  computed: {
    formatted() {
      return this.date && moment(this.date).format(this.format);
    }
  },

  data() {
    return {
      loading: false,
      holidays: [],
      availableChargeDates: [],
      date: null,
      menu: false,
      modal: false,
      key: null
    };
  },

  methods: {
    _setUniqKey() {
      this.key = `${uniqid(`${this.$attrs.label.replace(' ', '_')}_`)}`;
    },
    _setDate(v) {
      if (!v) { return; }
      this.date = (v && moment(v).format("YYYY-MM-DD")) || null;
    },

    allowedDates(val) {
      const min = this.min ? moment(this.min) : moment().add(-1, "y");
      const max = this.max ? moment(this.max) : moment().add(1, "y");
      const isBankHoliday = this.holidays.find((v) => v == val) != null;
      const current = moment(val);
      const greaterThanMinDate = current >= min;
      const lessThanMaxDate = current <= max;
      return greaterThanMinDate && lessThanMaxDate && !isBankHoliday;
    },

    async fetchBankHolidayes() {
      try {
        this.loading = true;
        const min = moment().add(-3, "M").format("YYYY-MM-DD");
        const max = moment().add(12, "M").format("YYYY-MM-DD");
        const response = await api.get(
          `/services/get-bank-holidays/?min=${min}&max=${max}`
        );
        if (response && response.status == 200) {
          this.holidays = response.data;
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "fetchBankHolidayes",
          },
          error
        );
        this.$emit("error", true);
      } finally {
        this.loading = false;
      }
    },

    async fetchAvailableChargeDates() {
      try {
        this.loading = true;
        const min = moment().add(-3, "M").format("YYYY-MM-DD");
        const max = moment().add(12, "M").format("YYYY-MM-DD");
        const response = await api.get(
          `/services/available-charge-dates/?min=${min}&max=${max}`
        );
        if (response && response.status == 200) {
          this.availableChargeDates = response.data;
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "fetchAvailableChargeDates",
          },
          error
        );
        this.$emit("error", true);
      } finally {
        this.loading = false;
      }
    },

    updated(v) {
      if (!v) { return; }
      this.$emit("input", moment(v).format("YYYY-MM-DD"));
    },
  },

  mounted() {
    this._setDate(this.value);
    this._setUniqKey();
    this.fetchAvailableChargeDates();
    if (this.blockBankHolidayes) this.fetchBankHolidayes();
  },
};
</script>

<style scoped>
.v-application--is-ltr .v-text-field>>>.v-input__append-inner,
.v-application--is-ltr .v-text-field>>>.v-input__prepend-inner {
  margin-left: auto;
  padding-left: 4px;
  margin-top: 8px !important;
}

.v-application--is-ltr .v-text-field>>>.v-input__append-inner .v-icon,
.v-application--is-ltr .v-text-field>>>.v-input__prepend-inner .v-icon {
  font-size: 18px;
}
</style>
