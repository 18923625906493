<template>
    <div id="notifications-container">
        <v-snackbar @input="notificationChanged(notification)" app v-model="notification.show"
            transition="slide-y-transition" 
            bottom
            absolute
            :multi-line="notification.multiLine" 
            :color="notification.color"
            :timeout="notification.timeout || 2000" 

            v-for="notification in notifications"
            :key="notification.id">
            <span>{{ notification.text }}</span>

            <v-btn text small @click="invoke(notification)" class="ml-5">
                {{ notification.btn_label || "dismiss" }}
                <v-icon v-if="notification.btn_icon" right>{{ notification.btn_icon }}</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {
        mapGetters
    } from "vuex";

    export default {
        name: "SysNotifications",

        computed: {
            ...mapGetters(["notifications"]),
        },

        methods: {
            notificationChanged(noti) {
                if (!noti.show && noti.auto_dismiss) this.$store.commit("REMOVE_NOTIFICATION", noti.id);
            },
            invoke(noti) {
                if (noti.btn_click && typeof (noti.btn_click) == "function") {
                    noti.btn_click();
                }
                this.$store.commit("REMOVE_NOTIFICATION", noti.id);
            }
        },
    }
</script>

<style lang="css" scoped>
    #notifications-container {
        width: 100%;
        text-align: center;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;
    }

    #notifications-container>>>.v-snack__content {
        align-items: center;
        justify-content: space-between;
        display: flex;
        width: 100%;
        margin: 0;
    }

    #notifications-container>>>.v-snack__content>button {
        margin: 0;
    }
</style>