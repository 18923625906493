<template>
    <v-text-field type="text" v-if="valid" :value="number" @change="updated" v-mask="currencyMask" v-bind="$attrs"
        :key="key" :rules="required ? requiredCurrencyRule : []">
        <template v-slot:append>
            <slot name="append">GBP</slot>
        </template>
    </v-text-field>

    <v-text-field type="text" v-else @change="updated" v-bind="$attrs" :key="`${key}-unvalid`" :rules="required ? requiredCurrencyRule : []">
        <template v-slot:append>
            <slot name="append">GBP</slot>
        </template>
    </v-text-field>
</template>

<script>
///https://github.com/probil/v-mask#readme
///https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import uniqid from 'uniqid';
const _currencyMask = createNumberMask({
    prefix: '£',
    allowDecimal: true,
    allowNegative: true
}); 


export default {
    name: "InputFieldCurrency",
    inheritAttrs: false,

    props: {
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        required: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        value(v, ov) {
            this.number = v || 0;
            this.key = `${uniqid(`${this.$attrs.label}_`)}`;
        },
    },

    computed: {
        valid() {
            if (!this.required) return true;
            return this.number != 0;
        }
    },

    data() {
        return {
            untouched: true,
            currencyMask: _currencyMask,
            number: this.value || 0,
            key: `${uniqid(`${this.$attrs.label}_`)}`,
            requiredCurrencyRule: [(v) => !!v || `Enter a currency value`],
        }
    },

    methods: {
        updated(v) {
            const val = v ? v.replace('£', '').replace(/,/g, '') : 0;
            const num = (val && parseFloat(val).toFixed(2));
            this.$emit('input', Number(num));
            this.$emit('change', Number(num));
        }
    },
}
</script>

<style scoped>
.v-application--is-ltr .v-text-field>>>.v-input__append-inner,
.v-application--is-ltr .v-text-field>>>.v-input__prepend-inner {
    margin-left: auto;
    padding-left: 4px;
}
</style>